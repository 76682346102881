/* Chatting.css */

.chat-container {
    display: flex;
    height: 100vh;

}


.chat-messages {
    flex: 2; /* Takes up two-thirds of the available width */
    overflow-y: auto;
    padding: 10px;
}


.message-container {
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
}

.message {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;

}

.own-message {
    align-self: flex-end;
    background-color: teal;
    color: white;
}

.other-message {
    align-self: flex-start;
    background-color: gray;
    color: white;
}

.message-input {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.message-input input {
    flex: 1;
    padding: 5px;
    border-radius: 10px;
    margin-right: 5px;
}

.message-input button {
    padding: 5px;
    background-color: teal;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.message-header {
    display: flex;
    justify-content: space-between;
}

.username {
    margin-right: 10px;
}


.message-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f0f0f0; /* Optional: Add a background color to the input container */

& button{
      width: 20%;
      height: 40px;
      background-color: #527CEC;
      color: #FFFFFF;
      border-radius:  10px;
&:hover {
     background-color: #3e3ee5;
 }
}
& input{
      border: 2px solid lightgrey;
      border-radius:  10px;
      padding: 10px 10px;
      display: block;
      margin: 0 auto;
      width: 80%;
      height: 40px;
  }
}
.icon-button {
    width: 10%;
    height: 40px;
    background-color: #527CEC;
    color: #FFFFFF;
    border-radius:  10px;
&:hover {
     background-color: #3e3ee5;
 }
}
