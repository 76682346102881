.forgot-password-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.forgot-password-card{
    background: #83c3ee;
    color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 60%;
}
.forgot-password-title{
    text-align: center; /* Center text horizontally */
    /*line-height: 100px; !* Center text vertically based on the card height *!*/
}

.forgot-password-input{
      border: 2px solid lightgrey;
      border-radius: 10px;
      padding: 10px 10px;
      display: block;
      margin: 0 auto;
  }
.forgot-password-custom-button {
    display: block;
    margin: 0 auto;
    width: 70%;
    height: 50px;
    background-color: #527CEC;
    color: #FFFFFF;
.forgot-password-custom-button:hover {
     background-color:blue;
 }
}