.modal-header {
    background-color: #c7e5ff !important;
    padding:10px;
}
.detect-label {
    display: inline-block;
    width: 200px;
}
.detect-edit {
    width: 400px;
}
.document-notes {
    width:100%;
    height:100px;
}
.dimension-col {
    width:20%;
    margin:0 5px;
    box-sizing:border-box;
}
.dim-label {
    display: inline-block;
    width: 200px;
}
.dim-edit {
    width: 200px;
}
.dimension-label {
    display: inline-block;
    width: 300px;
}
