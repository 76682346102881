.about {
    background-color: #CAD2E9;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:100vh;
    padding-bottom: 60px;
    .card{
        background-color: #FFFFFF;
        text-align: center;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        padding: 10px 10px;
        & img {
            display: block;
            margin: 0 auto;
        }
        & p, & h1 {
            padding: 20px 50px;
        }
        width: 90%;
        margin: 0 auto;
    }
}
.bottom-tab-nav {
    border-top: solid 1px #EBEBEB;
    background-color: #697ccb;
}
.bottom-nav-link {
    color: #00FAFF;
}
.bottom-nav-link.active {
    color: #FFFFFF;
}
