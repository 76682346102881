.login{
    background-color: #CAD2E9;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:100vh;
    padding-bottom: 60px;
    .card{
        background-color: #FFFFFF;
        text-align: center;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        & img {
            display: block;
            width: 100%;
            margin: 0 auto;
        }
        & form {
            display: flex;
            flex-direction: column;
            gap: 10 px;
        }
        & input{
            border: 2px solid lightgrey;
            border-radius: 5 px;
            padding: 10px 10px;
            display: block;
            margin: 0 auto;
            width: 80%;
        }
        & button{
            display: block;
            margin: 0 auto;
            width: 80%;
            background-color: #527CEC;
            color: #FFFFFF;
            &:hover {
                background-color: #3e3ee5;
            }
        }
        .custom-alert {
            width:80%;
            display: block;
            margin: 0 auto;
        }
        .button-text-h3 {
            font-size:1.5rem;
        }
        width: 90%;
        margin: 0 auto;
    }
    .bottom-tab-nav {
        border-top: solid 1px #EBEBEB;
        background-color: #697ccb;
    }
    .bottom-nav-link {
        color: #00FAFF;
    }
    .bottom-nav-link.active {
        color: #FFFFFF;
    }

}