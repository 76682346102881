.landing {
    background-color: #CAD2E9;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:100vh;
    padding-bottom: 60px;
    display: block;
    margin: 0 auto;
    .bottom-tab-nav {
        border-top: solid 1px #EBEBEB;
        background-color: #697ccb;
    }
    .bottom-nav-link {
        color: #00FAFF;
    }
    .bottom-nav-link.active {
        color: #FFFFFF;
    }
}
h1 {
    text-align: center;
}
& img {
    display: block;
    margin: 0 auto;
}